/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
/**
 * 
 * @export
 * @interface V1LocalProfileBusinessReviewPreview
 */
export interface V1LocalProfileBusinessReviewPreview {
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfileBusinessReviewPreview
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfileBusinessReviewPreview
     */
    content: string;
    /**
     * 
     * @type {number}
     * @memberof V1LocalProfileBusinessReviewPreview
     */
    starRating: number;
    /**
     * 
     * @type {Date}
     * @memberof V1LocalProfileBusinessReviewPreview
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfileBusinessReviewPreview
     */
    reviewerNickname: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfileBusinessReviewPreview
     */
    reviewerProfileImageUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1LocalProfileBusinessReviewPreview
     */
    imageUrls: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof V1LocalProfileBusinessReviewPreview
     */
    likeCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof V1LocalProfileBusinessReviewPreview
     */
    likedByMe: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof V1LocalProfileBusinessReviewPreview
     */
    writtenByMe: boolean;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfileBusinessReviewPreview
     */
    localProfileId: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfileBusinessReviewPreview
     */
    localProfileName: string;
    /**
     * 
     * @type {number}
     * @memberof V1LocalProfileBusinessReviewPreview
     */
    localProfileReviewCount: number;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfileBusinessReviewPreview
     */
    localProfileCategoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfileBusinessReviewPreview
     */
    localProfileProfileImageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof V1LocalProfileBusinessReviewPreview
     */
    reviewerReviewsViewMoreTargetUri: string;
}

/**
 * Check if a given object implements the V1LocalProfileBusinessReviewPreview interface.
 */
export function instanceOfV1LocalProfileBusinessReviewPreview(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "starRating" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "reviewerNickname" in value;
    isInstance = isInstance && "imageUrls" in value;
    isInstance = isInstance && "likeCount" in value;
    isInstance = isInstance && "likedByMe" in value;
    isInstance = isInstance && "writtenByMe" in value;
    isInstance = isInstance && "localProfileId" in value;
    isInstance = isInstance && "localProfileName" in value;
    isInstance = isInstance && "localProfileReviewCount" in value;
    isInstance = isInstance && "reviewerReviewsViewMoreTargetUri" in value;

    return isInstance;
}

export function V1LocalProfileBusinessReviewPreviewFromJSON(json: any): V1LocalProfileBusinessReviewPreview {
    return V1LocalProfileBusinessReviewPreviewFromJSONTyped(json, false);
}

export function V1LocalProfileBusinessReviewPreviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1LocalProfileBusinessReviewPreview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'content': json['content'],
        'starRating': json['star_rating'],
        'createdAt': (new Date(json['created_at'])),
        'reviewerNickname': json['reviewer_nickname'],
        'reviewerProfileImageUrl': !exists(json, 'reviewer_profile_image_url') ? undefined : json['reviewer_profile_image_url'],
        'imageUrls': json['image_urls'],
        'likeCount': json['like_count'],
        'likedByMe': json['liked_by_me'],
        'writtenByMe': json['written_by_me'],
        'localProfileId': json['local_profile_id'],
        'localProfileName': json['local_profile_name'],
        'localProfileReviewCount': json['local_profile_review_count'],
        'localProfileCategoryName': !exists(json, 'local_profile_category_name') ? undefined : json['local_profile_category_name'],
        'localProfileProfileImageUrl': !exists(json, 'local_profile_profile_image_url') ? undefined : json['local_profile_profile_image_url'],
        'reviewerReviewsViewMoreTargetUri': json['reviewer_reviews_view_more_target_uri'],
    };
}

export function V1LocalProfileBusinessReviewPreviewToJSON(value?: V1LocalProfileBusinessReviewPreview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'content': value.content,
        'star_rating': value.starRating,
        'created_at': (value.createdAt.toISOString()),
        'reviewer_nickname': value.reviewerNickname,
        'reviewer_profile_image_url': value.reviewerProfileImageUrl,
        'image_urls': value.imageUrls,
        'like_count': value.likeCount,
        'liked_by_me': value.likedByMe,
        'written_by_me': value.writtenByMe,
        'local_profile_id': value.localProfileId,
        'local_profile_name': value.localProfileName,
        'local_profile_review_count': value.localProfileReviewCount,
        'local_profile_category_name': value.localProfileCategoryName,
        'local_profile_profile_image_url': value.localProfileProfileImageUrl,
        'reviewer_reviews_view_more_target_uri': value.reviewerReviewsViewMoreTargetUri,
    };
}

