/* tslint:disable */
/* eslint-disable */
/**
 * Local Map - Local Map Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.3
 * Contact: local-map@daangn.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../runtime';
import type { SuggestionKeywordType } from './SuggestionKeywordType';
import {
    SuggestionKeywordTypeFromJSON,
    SuggestionKeywordTypeFromJSONTyped,
    SuggestionKeywordTypeToJSON,
} from './SuggestionKeywordType';

/**
 * 
 * @export
 * @interface SearchCategoryKeywordsSuggestionSuggestion
 */
export interface SearchCategoryKeywordsSuggestionSuggestion {
    /**
     * 
     * @type {SuggestionKeywordType}
     * @memberof SearchCategoryKeywordsSuggestionSuggestion
     */
    keywordType: SuggestionKeywordType;
    /**
     * 
     * @type {string}
     * @memberof SearchCategoryKeywordsSuggestionSuggestion
     */
    keyword: string;
    /**
     * 
     * @type {number}
     * @memberof SearchCategoryKeywordsSuggestionSuggestion
     */
    displayOrder: number;
}

/**
 * Check if a given object implements the SearchCategoryKeywordsSuggestionSuggestion interface.
 */
export function instanceOfSearchCategoryKeywordsSuggestionSuggestion(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "keywordType" in value;
    isInstance = isInstance && "keyword" in value;
    isInstance = isInstance && "displayOrder" in value;

    return isInstance;
}

export function SearchCategoryKeywordsSuggestionSuggestionFromJSON(json: any): SearchCategoryKeywordsSuggestionSuggestion {
    return SearchCategoryKeywordsSuggestionSuggestionFromJSONTyped(json, false);
}

export function SearchCategoryKeywordsSuggestionSuggestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchCategoryKeywordsSuggestionSuggestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'keywordType': SuggestionKeywordTypeFromJSON(json['keyword_type']),
        'keyword': json['keyword'],
        'displayOrder': json['display_order'],
    };
}

export function SearchCategoryKeywordsSuggestionSuggestionToJSON(value?: SearchCategoryKeywordsSuggestionSuggestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'keyword_type': SuggestionKeywordTypeToJSON(value.keywordType),
        'keyword': value.keyword,
        'display_order': value.displayOrder,
    };
}

